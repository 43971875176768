<template>
    <div>
        <h1>Select company</h1>
    </div>
</template>

<script>
export default {
    name: 'SelectCompanyComponent'
}
</script>